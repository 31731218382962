.wrapper {
  display: flex;
  flex-flow: column nowrap;
  background-color: #f1f1f1;
  padding: 60px 0 20px;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.navBar {
  position: absolute;
  height: 60px;
  line-height: normal;
  font-size: 12px;
  text-align: center;
  color: #c0a76e;
  width: 100%;
}

.navIcon {
  width: 25px;
  height: 25px;
}

.navIconImgChat {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 10px;
}

.containerFirstBtn {
  display: flex;
  margin-left: 15px;
  margin-right: 15px;
}

.horizontalTextLink {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.bottomTextLink1 {
  width: 100%;
}

.bottomTextLink2 {
  width: 100%;
}
.headerContainer {
  align-items: center;
  margin: 10px;
  justify-content: center;
  flex-direction: row;
  display: flex;
}
.boxHeaderContainer {
  align-items: center;
  height: 75px;
  justify-content: space-between;
  flex-direction: row;
  display: flex;
  border-radius: 8px;
  background-color: #fff;
  width: 82%;
  margin: auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 0 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.boxHeaderContent {
  display: flex;
  align-items: center;
  max-width: 88%;
}

.boxContent {
  width: 90%;
}

.headerContent {
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  font-weight: 400;
}

.headerText {
  color: #a0a0a0;
  margin-left: 10px;
  margin-right: 10px;
  /*align-self: center;*/
}

.textHorizontalLine {
  width: 90%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-column-gap: 9px;
  align-items: center;
  font-size: 13.5px;
  letter-spacing: 1px;
  color: #a0a0a0;
  margin-top: 5px;
  margin-bottom: 10px;
}
.textHorizontalLine::after,
.textHorizontalLine::before {
  content: '';
  height: 1px;
  display: block;
  background-color: currentColor;
}
.horizontalLine {
  width: 10%;
  height: 1px;
  background: #a0a0a0;
}
.wrapperWhiteMode {
  background-color: #f8f8f8;
}
.activeWhiteMode {
  background-color: #c89c34;
}
.textGoldWhiteMode {
  color: #c89c34;
}
.textLightDarkGray {
  color: #999999;
}
