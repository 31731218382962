:root {
  --NotoSans: 'Noto Sans Japanese', sans-serif;
  --Roboto: 'Roboto', sans-serif;
  --NotoSerif: 'Noto Serif', serif;
}

html,
body,
#app,
#app > div {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--NotoSans) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
  -webkit-overflow-scrolling: unset;
  overflow: hidden;
}

body.isIOSSafari {
  /* fix full screen problem in ios safari */
  height: calc(100vh + 1px);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

#root.isIOSSafari {
  /* fix full screen problem in ios safari */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

:global(.am-tab-bar-bar::before) {
  background-color: #111 !important;
}

.weui-picker__item {
  font-size: 18px !important;
}

.am-tab-bar-bar {
  height: 65px !important;
  align-items: flex-start !important;
  padding-top: 10px;
}