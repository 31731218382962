.messageContainer {
  margin-top: 36px;
  padding: 0 2px;
}

.title {
  font-weight: 600;
  line-height: normal;
  font-size: 18px;
  text-align: center;
  color: #000000;
}

.message1 {
  line-height: normal;
  font-size: 15px;
  color: #242728;
  margin-bottom: 28px;
}

.message2 {
  line-height: normal;
  font-size: 15px;
  color: #242728;
}
