.cancel-popup .message-container {
    margin-top: 40px;
}

.cancel-popup .message-multiple-lines {
    line-height: 22px;
    margin-top: 20px;
    color: #242728;
}

.cancel-popup .message-multiple-lines strong {
    font-weight: bold;
}