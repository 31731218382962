.containerOverlay {
  position: fixed; /* Sit on top of the page content */
  display: inline; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2222;
}

.wrapper {
  flex: 10;
  flex-direction: column;
  width: 85%;
  height: auto;
  background-color: #e5e5e5;
  z-index: 1005;
  border-radius: 8px;
  padding-left: 21px;
  padding-right: 21px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  line-height: normal;
  font-size: 18px;
  text-align: center;
  color: #000000;
  font-weight: 600;
}

.content {
  height: auto;
  line-height: normal;
  font-size: 15px;
  color: #242728;
  margin-top: 35px;
}

.footer {
  height: auto;
  margin-top: 40px;
}

.closeButtonContainer {
  position: absolute;
  right: -15px;
  top: -15px;
  width: 31px;
  height: 31px;
  opacity: 1;
  background-color: #e5e5e5;
  z-index: 1010;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 46px;
}

.button {
  display: flex;
  height: 60px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #c0a76e;
  font-weight: 400;
  color: #000;
  font-size: 16.6px;
  line-height: normal;
  font-size: 16.6px;
  text-align: center;
  letter-spacing: 1px;
}

.closeButton {
  position: absolute;
  width: 27px;
  height: 27px;
  border-radius: 13.5px;
  opacity: 1;
  background-color: #c0a76e;
  z-index: 1010;
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeButton:hover {
  opacity: 1;
}
.closeButton:before,
.closeButton:after {
  position: absolute;
  content: ' ';
  height: 14px;
  width: 1px;
  background-color: white;
}
.closeButton:before {
  transform: rotate(45deg);
}
.closeButton:after {
  transform: rotate(-45deg);
}
