.navIcon {
  width: 25px;
  height: 25px;
}
.containerFull {
  width: 100%;
  height: 100%;
  background-color: #111111;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.confirmationPopupTitle {
  line-height: normal;
  font-size: 18px;
  text-align: center;
  color: #000000;
  font-weight: 600;
}
.suddenlyDroppedContainer {
  text-align: right;
  margin-top: 16px;
}
.suddenlyDropped {
  color: #c0a76e;
  text-decoration: underline;
}
.messageClassName {
  font-size: 15px;
  color: #242728;
  display: flex;
  justify-content: center;
}
.displayUnset {
  display: unset !important;
}
.messageSpace {
  margin-bottom: 20px;
}
.messageSpace:last-child {
  margin-bottom: 0;
}
.confirmationPopupFooter {
  margin-top: 43px;
}

.messageClassNameCancelSampleDate {
  font-size: 15px;
  color: #242728;
  margin-top: 20px;
}

.confirmationPopupMessagesContainer {
  margin-top: 32px;
}
.contentContainer {
  background-color: #111111;
  flex: 1;
  overflow: auto;
}

.premiumCrown {
  vertical-align: top;
  padding-right: 4px;
  width: 36px;
  height: 22px;
  margin-bottom: 2px;
}
@media screen and (device-aspect-ratio: 40/71) {
  /* .content-container {
    padding-top: 60px;
  } */
}
.containerAvatar {
  margin-top: 27px;
  position: relative;
  text-align: center;
  color: white;
  margin-bottom: 25px;
}
.imageAvatarPartner {
  width: 80px;
  height: 80px;
  border-radius: 160px;
  margin-bottom: 18px;
}

.blur {
  -webkit-filter: blur(2px); /* Safari 6.0 - 9.0 */
  filter: blur(2px);
}

.centeredName {
  position: absolute;
  top: 110%;
  left: 50%;
  color: white;
  font-size: 18px;
  line-height: 21px;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  resize: horizontal;
  max-width: 80%;
}
.centeredNameWhite {
  color: black;
}
.trancert-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  resize: horizontal;
}
.listDetail {
  flex-direction: row;
  background-color: #111111;
}
.listDetailWhite {
  flex-direction: row;
  background-color: #F8F8F8;
}
.labelTitleWhite {
  color: black;
}
.labelTitle {
  display: flex;
  width: 100%;
  padding-top: 31px;
  padding-bottom: 31px;
  padding-left: 22px;
  padding-right: 22px;
  border-bottom: 0.1px #575757 solid;
  align-items: center;
  justify-content: space-between;
}
.labelContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 18px;
}
.labelNonBorder {
  border-bottom: 0px;
}
.paddingVertical {
  background-color: #eaeaea;
  margin-left: 11px;
  margin-right: 11px;
}
.containerCoffe {
  position: relative;
  text-align: center;
  display: flex;
}
.imageCoffee {
  flex: 0 0 auto;
  height: 267px;
  width: 100%;
  object-fit: cover;
}
.backgroundCoffee {
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to bottom, transparent 60%, black 100%);
}
.txtCoffee {
  position: absolute;
  bottom: 8px;
  left: 16px;
  font-size: 20px;
  line-height: 23px;
  text-align: left;
  font-weight: 200;
  color: white;
  margin-bottom: 25px;
  /* transform: translate(-50%, -50%); */
}

.premium-crown {
  vertical-align: bottom;
  padding-right: 4px;
  width: 36px;
  height: 22px;
}

.crownArea {
  width: 100%;
  color: white;
  font-size: 18px;
  line-height: 21px;
  vertical-align: top;
}

.txtTitleCrown {
  width: 100%;
  color: white;
  font-size: 18px;
  line-height: 21px;
  vertical-align: bottom;
  margin-left: 2px;
}
.titleClassName {
  font-weight: 600;
  color: #000;
  line-height: 21px;
  font-size: 18px;
  text-align: center;
}
.txtTitleUserRating {
  width: 100%;
  color: #c0a76e;
  font-size: 28px;
  line-height: 21px;
  margin-left: 2px;
}

.txtTitle {
  width: 100%;
  color: white;
  font-size: 18px;
  line-height: 21px;
}
.txtTitleWhite {
  color: black;
}
.txtTitleRight {
  margin-top: 18px;
  font-size: 15px;
  line-height: 20px;
  text-align: right;
  text-decoration: underline;
  cursor: pointer;
}

.labelSub {
  display: flex;
  width: 100%;
  padding: 23px 22px;
  border-bottom: 0.1px #575757 solid;
  align-items: center;
  justify-content: space-between;
}

.relativePosition {
  position: relative !important;
}

.labelSubBtn {
  display: flex;
  width: 100%;
  padding-top: 43px;
  padding-bottom: 43px;
  padding-left: 22px;
  padding-right: 22px;
  align-items: center;
  justify-content: space-between;
}

.txtSub {
  color: #a0a0a0;
  font-size: 16px;
  line-height: 19px;
}
.txtSubWhite {
  color: black;
}
.txtSubRightContainer {
  flex: 0 0 auto;
  max-width: 50%;
  display: inline-block;
  line-height: initial;
}
.txtSubRightContainerCrown {
  position: absolute;
  right: 22px;
  bottom: 5px;
}
.txtSubRightCrown {
  font-size: 13px;
  text-decoration: underline;
  display: inline-block;
  float: right;
  margin-top: 5px;
}
.txtSubRight {
  max-width: 100%;
  word-wrap: break-word;
  text-align: left;
}

.contentPlace {
  padding-top: 13px;
  padding-bottom: 8px;
  padding-left: 11px;
  width: 100%;
  display: flex;
}
.contentPlaceFisrt {
  padding-top: 30px;
  padding-bottom: 8px;
  padding-left: 11px;
  width: 100%;
  display: flex;
}
.contentPlaceLast {
  padding-top: 13px;
  padding-bottom: 15px;
  padding-left: 11px;
  width: 100%;
  display: flex;
}
.labelPlace {
  color: #575757;
  font-size: 13px;
  line-height: 15px;
  flex: 1;
}
.txtContentPlace {
  font-size: 15px;
  line-height: 22px;
  color: #000000;
  flex: 2;
  padding-right: 11px;
}
.starClass {
  color: white;
  background-color: #c0a76e;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 1px;
  padding-bottom: 1px;
  border-radius: 3px;
  margin-left: 2px;
}

.navIcon {
  width: 25px;
  height: 25px;
}
.btnDialogBtm {
  width: 100%;
  height: 60px;
  background: #c0a76e;
  border-radius: 45px;
  border: 0px solid #c0a76e;
  line-height: normal;
  color: #fff;
  /*color: #000000;*/
  font-size: 16.6px;
  letter-spacing: 1px;
}
.messageClassName {
  font-size: 15px;
  color: #242728;
}
.greyButton {
  width: 86%;
  height: 35px;
  margin-right: 7px;
  background: #222222;
  color: #7c7c7c;
  border-radius: 8px;
  display: flex;
  font-size: 15px;
  margin-top: 10%;
  margin-bottom: 10%;
  flex-direction: row;
  align-items: center;
  padding: 25px;
  justify-content: center;
}
.goldenButton {
  width: 86%;
  height: 60px;
  background: #c0a76e;
  border-radius: 45px;
  color: #000000;
  display: flex;
  font-size: 16px;
  margin-top: 10%;
  margin-bottom: 12%;
  flex-direction: row;
  align-items: center;
  padding: 25px;
  justify-content: center;
}
.buttons {
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
  /*margin-bottom: 52px;*/
  justify-content: center;
  align-items: center;
}
.title {
  font-weight: bold;
  font-size: 18px;
  line-height: normal;
  text-align: center;
  font-weight: 600;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 46px;
}

.button {
  display: flex;
  height: 60px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #c0a76e;
  line-height: 19px;
  font-size: 12px;
  font-weight: 500;
}

.buttonOk {
  color: #000;
  line-height: normal;
  font-size: 16px;
  text-align: center;
  letter-spacing: 1px;
  font-weight: 400;
}

.buttonPositive {
  margin-bottom: 16px;
}

.buttonNegative {
  background-color: #a0a0a0;
}

.note {
  font-size: 15px;
  text-align: center;
  font-weight: 400;
  color: #047bfd;
  text-decoration: underline;
}

.messageContainer {
  margin-top: 10px;
}

.messageContainerPopupDatingTime {
  margin-top: 20px;
}

.message {
  line-height: normal;
  font-size: 14px;
  text-align: left;
  padding-top: 20px;
}

.messagePopupDatingTime {
  line-height: normal;
  font-size: 15px;
  text-align: left;
  padding-top: 5px;
}

.messageLast {
  line-height: normal;
  font-size: 15px;
  text-align: left;
}

ul {
  padding-left: 15px;
}

li {
  padding-top: 20px;
}

li:first-child {
  padding-top: 0px;
}

.contact {
  font-size: 15px;
  font-weight: 400;
  color: #047bfd;
  text-decoration: underline;
}
.containerBlurCredit {
  display: flex;
  justify-content: center;
  height: 600px;
}
.labelSubBtnAbsolute {
  position: absolute;
  bottom: 20px;
  z-index: 100;
  display: flex;
  width: 100%;
  padding-top: 23px;
  padding-bottom: 23px;
  padding-left: 22px;
  padding-right: 22px;
  align-items: center;
  justify-content: space-between;
}
.blurDiv {
  background-image: url('../../assets/blur1.png');

  /* Add the blur effect */
  /* filter: blur(8px);
  -webkit-filter: blur(8px); */

  /* Full height */
  height: 600px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: 85px;
  position: absolute;
  z-index: 50;

  /* Center and scale the image nicely */
  background-position: center;
  /* background-repeat: no-repeat; */
  background-size: contain;

  justify-content: center;
  align-items: center;
}
.containerBlurCredit1 {
  display: flex;
  justify-content: center;
  height: 200px;
}
.blurDiv1 {
  background-image: url('../../assets/blur2.png');
  /* filter: blur(8px);
  -webkit-filter: blur(8px); */
  height: 200px;
  background-position: center;
  /* background-repeat: no-repeat; */
  background-size: contain;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 50;
}
.noCardBlur {
  filter: blur(5px) brightness(0.75);
  -webkit-filter: blur(3px) brightness(0.75);
  -moz-filter: blur(5px) brightness(0.75);
  -ms-filter: blur(5px) brightness(0.75);
  -o-filter: blur(5px) brightness(0.75);
}

.headerPopUp {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.titlePopUp {
  line-height: normal;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
}

.closeBtnContainer {
  position: absolute;
  right: -10px;
  top: -10px;
  width: 20px;
  height: 20px;
  opacity: 1;
  background-color: #e5e5e5;
  z-index: 1010;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.closeBtn {
  position: absolute;
  width: 17px;
  height: 17px;
  border-radius: 8.5px;
  opacity: 1;
  background-color: #c0a76e;
  z-index: 1010;
  display: flex;
  align-items: center;
  justify-content: center;
}
.triangleDown {
  position: absolute;
  bottom: -7px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 10px solid #c0a76e;
}
.containerTooltips {
  display: flex;
  background-color: #c0a76e;
  width: 95%;
  height: 38px;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  margin: -25px 8px;
  padding: 10px;
  position: relative;
}

.closeButtonXContainer {
  position: absolute;
  right: -9px;
  top: -9px;
  width: 18px;
  height: 18px;
  opacity: 1;
  background-color: #e5e5e5;
  z-index: 1010;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.closeButtonX {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  opacity: 1;
  background-color: #c0a76e;
  z-index: 1010;
  display: flex;
  align-items: center;
  justify-content: center;
}
.closeButtonX:hover {
  opacity: 1;
}
.closeButtonX:before,
.closeButtonX:after {
  position: absolute;
  content: ' ';
  height: 10px;
  width: 1px;
  margin-left: 0.5px;
  background-color: white;
}
.closeButtonX:before {
  transform: rotate(45deg);
}
.closeButtonX:after {
  transform: rotate(-45deg);
}

.iconQuestion {
  height: 22px;
  width: 20px;
  margin-left: 4px;
}

.iconQuestionYellow {
  height: 20px;
  width: 18px;
  margin-left: 4px;
  vertical-align: bottom;
}

.tabButton {
  font-size: 15px;
  width: 100%;
  height: 41px;
  color: #ffffff;
  background: linear-gradient(182.87deg, #646464 -12.73%, #242424 80.72%);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
}
.tabButtonWhite {
  background: #C4C4C4;
}
.tabSelected {
  background: #c0a76e;
}
.leftTab {
  margin-left: 10px;
  margin-right: 2.5px;
}
.rightTab {
  margin-left: 2.5px;
  margin-right: 10px;
}
.tabSection {
  display: flex;
  margin-top: 3rem;
}
.warningText {
  color: #ff0000;
  font-size: 11px;
}
.buttonDate {
  display: flex;
  height: 60px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #c0a76e;
  color: #000;
  font-size: 16.6px;
  line-height: normal;
  letter-spacing: 1px;
  font-weight: 400;
}
.buttonNegative {
  background-color: #a0a0a0;
}

.labelCancel {
  padding-left: 11px;
  padding-right: 11px;
}

.cancelButton {
  width: 86%;
  height: 35px;
  background: #222222;
  color: #7c7c7c;
  border-radius: 8px;
  display: flex;
  font-size: 15px;
  margin-top: 10%;
  margin-bottom: 15%;
  flex-direction: row;
  align-items: center;
  padding: 25px;
  justify-content: center;
}
.cancelButton.isSampleDate {
  margin: 20px 0 15px;
}
.txtTitleCafeInfo {
  margin-left: 11px;
}
.schoolContent {
  max-width: 80%;
}

.notifyPopup {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
}

.checkboxContainer {
  display: flex;
  flex-flow: row wrap;
  margin: 30px 5px 0 5px;
}
.containerFullWhite {
  background-color: white;
}
.navBarWhite {
  border-bottom: 2px solid white;
  background-color: white;
}
.activeWhiteMode {
  background-color: #c89c34;
}
.textGoldWhiteMode {
  color: #c89c34;
}
.containerFullWhite {
  background-color: #F8F8F8;
}
.textWhite {
  color: white;
}
@media screen and (min-width: 320px) and (max-width: 361px) {
  .notifyPopup {
    font-size: 12px;
  }
  .labelTitle {
    padding-top: 10px;
  }
}

.noFloating {
  position: relative;
}
.noFloating .labelSubBtnAbsolute {
  bottom: auto;
  margin-top: 100px;
  padding-top: 0;
}
.noFloating .labelSubBtnAbsolute2 {
  bottom: 120px;
  background: transparent;
}
.noFloating .labelSubBtnAbsolute.tab2 {
  margin-top: 150px;
}
.detailInfoLinkBtn {
  color: #c0a76e;
  width: fit-content;
  margin-top: unset;
  line-height: unset;
  float: right;
  font-weight: bold;
}
.detailInfoLinkGroup {
  width: 100%;
  float: right;
  margin-top: 18px;
}

/* iphone 5 */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (-webkit-device-pixel-ratio: 2) {
  .txtSubRightCrown {
    font-size: 13px;
  }
  .txtTitleCrown {
    font-size: 16px;
  }
}

/* iphone 6, 6s, 7, 8 */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
  .txtSubRightCrown {
    font-size: 13px;
  }
  .txtTitleCrown {
    font-size: 16px;
  }
}

/* samsung galaxy s8+ */
@media only screen and (min-device-width: 360px) and (max-device-height: 740px) {
  .txtSubRightCrown {
    font-size: 13px;
  }
  .txtTitleCrown {
    font-size: 15px;
  }
}
