.title {
  font-weight: bold;
  font-size: 18px;
  line-height: normal;
  text-align: center;
  font-weight: 600;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 46px;
}

.button {
  display: flex;
  height: 60px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #c0a76e;
  line-height: 19px;
  font-size: 12px;
  font-weight: 500;
}

.buttonOk {
  color: #000;
  line-height: normal;
  font-size: 16px;
  text-align: center;
  letter-spacing: 1px;
  font-weight: 400;
}

.buttonPositive {
  margin-bottom: 16px;
}

.buttonNegative {
  background-color: #a0a0a0;
}

.note {
  font-size: 15px;
  text-align: center;
  font-weight: 400;
  color: #047BFD;
  text-decoration: underline;
}

.messageContainer {
  margin-top: 25px;;
}

.message {
  line-height: normal;
  font-size: 15px;
  text-align: left;
  margin-top: 22px;
}

.list {
  padding-left: 15px !important; 
  margin-left: 0px !important;
}

.textLink {
  padding-top: 22px !important;;
  font-size: 15px;
  font-weight: 400;
  color: 'black';
}

.link {
  text-decoration: underline;
}
